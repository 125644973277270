<template>
  <div>
    <KTCodePreview v-bind:title="'Báo cáo sản phẩm bán chạy'">
      <template v-slot:toolbar>
        <div class="row">
          <div class="col-md-5">
            <b-dropdown size="sm" id="dropdown-1" right>
              <template slot="button-content">
                <i style="font-size: 1rem" class="fas fa-cog"></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="reportClick">
                <span>
                  <i style="font-size: 1rem" class="far fa-file-excel"></i>
                  &nbsp; Xuất Excel</span
                >
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <b-row class="ml-1">
          <div class="col-md-12">
            <div class="row">
              <b-input
                size="sm"
                placeholder="Nhập tên, mã, mã vạch sản phẩm"
                v-model="searchProduct"
                append-icon="search"
                single-line
                hide-details
                class="col-md-3 mb-4"
                v-on:keyup.enter="onFilter()"
              ></b-input>
              <div class="col-md-4">
                <vue-autosuggest
                  class="col-md-10"
                  v-model="selectedCateName"
                  :suggestions="filteredCateOptions"
                  @selected="onSelectedCate"
                  :limit="200"
                  @input="onInputCateChange"
                  :input-props="{
                    id: 'autosuggest__input',
                    placeholder: 'Chọn danh mục',
                  }"
                >
                  <div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    {{ suggestion.item.suggestionName }}
                  </div></vue-autosuggest
                >
              </div>
              <b-col md="2" class="pl-0">
                <date-picker
                  placeholder="Từ ngày"
                  class="form-control-sm"
                  :config="dpConfigs.start"
                  v-model="dpForm.startDate"
                ></date-picker>
              </b-col>
              <b-col md="2">
                <date-picker
                  placeholder="Đến ngày"
                  class="form-control-sm"
                  :config="dpConfigs.end"
                  v-model="dpForm.endDate"
                ></date-picker>
              </b-col>
            </div>
          </div>
        </b-row>
        <b-row class="ml-1">
          <div class="col-md-12">
            <div class="row mb-4">
              <div class="col-md-3 pl-0">
                <div class="row">
                  <b-lable class="col-md-4 d-flex align-items-center"
                    >Thể loại:</b-lable
                  >
                  <b-form-select
                    size="sm"
                    required
                    v-model="selectedProductType"
                    :options="listProductType"
                    value-field="id"
                    text-field="name"
                    disabled-field="notEnabled"
                    class="col-md-8"
                  ></b-form-select>
                </div>
              </div>
              <div class="col-md-4">
                <vue-autosuggest
                  class="col-md-10"
                  v-model="selectedCate2Name"
                  :suggestions="filteredCate2Options"
                  @selected="onSelectedCate2"
                  :limit="200"
                  @input="onInputCate2Change"
                  :input-props="{
                    id: 'autosuggest__input',
                    placeholder: 'Chọn danh mục nội bộ',
                  }"
                >
                  <div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    {{ suggestion.item.suggestionName }}
                  </div></vue-autosuggest
                >
              </div>
              <b-col md="2" class="pl-0">
                <div>
                  <!-- <div class="row"> -->
                  <BDropdownCustom
                    v-model="value"
                    :options="options"
                    :placeholder="'cửa hàng'"
                    :searchable="true"
                    :matchKey="['name']"
                    :limit="5"
                  />
                  <!-- </div> -->
                </div>
              </b-col>
              <!-- <b-col md="3">
                    <vue-autosuggest
                      class="col-md-9"
                      v-model="searchEmployee"
                      :suggestions="filteredOptionsEmployee"
                      @selected="onSelectedEmployee"
                      :limit="10"
                      @input="onInputChangeEmployee"
                      :input-props="{
                        id: 'autosuggest__input',
                        placeholder: 'Nhân viên bán hàng',
                      }"
                    >
                      <div
                        slot-scope="{ suggestion }"
                        style="display: flex; align-items: center"
                      >
                        {{ suggestion.item.fullName }}
                      </div></vue-autosuggest
                    >
                  </b-col> -->
            </div>
          </div>
        </b-row>
        <b-row class="pl-0">
          <b-col md="10" class="pl-0">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter()"
              class="col-md-8 mb-4 ml-4"
              >Lọc</b-button
            >
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <p
                class="mt-3 text-dark"
                style="font-weight: 500; font-size: 13px"
              >
                {{ fromNo }} - {{ toNo }} /
                {{ totalItem }}
              </p>
            </div>
          </b-col>
        </b-row>
        <div>
          <b-table
            :items="listItem"
            :fields="fields"
            sort-by="count"
            class="table-bordered table-hover col-md-12"
            :busy="onLoading"
          >
            <template slot="thead-top">
              <tr>
                <th class="text-center">Sản phẩm</th>
                <th class="text-center">Tồn</th>
                <th class="text-center" colspan="2">Chuyển hàng</th>
                <th class="text-center" colspan="2">Bán lẻ</th>
                <th class="text-center" colspan="2">Bán buôn</th>
                <th class="text-center">Tổng SL bán</th>
                <th class="text-center">Giá vốn</th>
                <th class="text-center">Giá bán</th>
                <th class="text-center">Chiết khấu</th>
                <th class="text-center">Doanh thu</th>
                <th class="text-center">Lợi nhuận</th>
              </tr>
            </template>
            <template v-slot:table-busy>
              <vcl-table :speed="5" :animate="true" :columns="8"></vcl-table>
            </template>

            <template v-slot:cell(count)="row">
              <div style="text-align: center">
                <span v-text="row.item.count" class="text-right"></span>
              </div>
            </template>
            <template v-slot:cell(productName)="row">
              <!-- <span
                    v-if="row.item.barCode"
                    v-text="row.item.barCode"
                    style="
                      white-space: normal;
                      font-size: 12px;
                      font-weight: 500;
                    "
                  ></span>
                  <p
                    v-if="row.item.productCode"
                    style="font-size: 12px; font-weight: 500; margin-bottom: 0"
                  >
                    ( {{ row.item.productCode }} )
                  </p> -->
              <span
                v-text="row.item.productName"
                style="white-space: normal; color: #3699ff"
              ></span>
            </template>
            <template v-slot:cell(totalQuantityInStock)="row">
              <div style="text-align: right">
                <span
                  v-if="row.item.totalQuantityInStock > 0"
                  v-text="formatMoney(row.item.totalQuantityInStock)"
                  class="text-right text-report"
                ></span>
              </div>
            </template>
            <template v-slot:cell(saleShipping)="row">
              <div style="text-align: right">
                <span
                  v-if="row.item.saleShipping > 0"
                  v-text="formatMoney(row.item.saleShipping)"
                  class="text-right text-report"
                ></span>
              </div>
            </template>
            <template v-slot:cell(returnShipping)="row">
              <div style="text-align: right">
                <span
                  v-if="row.item.returnShipping > 0"
                  v-text="formatMoney(row.item.returnShipping)"
                  class="text-right text-report"
                ></span>
              </div>
            </template>
            <template v-slot:cell(saleRetail)="row">
              <div style="text-align: right">
                <span
                  v-if="row.item.saleRetail > 0"
                  v-text="formatMoney(row.item.saleRetail)"
                  class="text-right text-report"
                ></span>
              </div>
            </template>
            <template v-slot:cell(returnRetail)="row">
              <div style="text-align: right">
                <span
                  v-if="row.item.returnRetail > 0"
                  v-text="formatMoney(row.item.returnRetail)"
                  class="text-right text-report"
                ></span>
              </div>
            </template>
            <template v-slot:cell(saleWhosesale)="row">
              <div style="text-align: right">
                <span
                  v-if="row.item.saleWhosesale > 0"
                  v-text="formatMoney(row.item.saleWhosesale)"
                  class="text-right text-report"
                ></span>
              </div>
            </template>
            <template v-slot:cell(returnWhosesale)="row">
              <div style="text-align: right">
                <span
                  v-if="row.item.returnWhosesale > 0"
                  v-text="formatMoney(row.item.returnWhosesale)"
                  class="text-right text-report"
                ></span>
              </div>
            </template>
            <template v-slot:cell(sellingPrice)="row">
              <div style="text-align: right">
                <span
                  v-text="formatMoney(row.item.sellingPrice)"
                  class="text-right text-report"
                ></span>
              </div>
            </template>
            <template v-slot:cell(totalSale)="row">
              <div style="text-align: right; color: #3699ff">
                <span
                  v-text="formatMoney(row.item.totalSale)"
                  class="text-right text-report"
                ></span>
              </div>
            </template>
            <template v-slot:cell(totalSellingAmount)="row">
              <div style="text-align: right">
                <span
                  v-text="formatMoney(row.item.totalSellingAmount)"
                  class="text-right text-report"
                ></span>
              </div>
            </template>
            <template v-slot:cell(totalReturn)="row">
              <div style="text-align: right">
                <span
                  v-text="formatMoney(row.item.totalReturn)"
                  class="text-right text-danger text-report"
                ></span>
              </div>
            </template>
            <template v-slot:cell(totalReturnAmount)="row">
              <div style="text-align: right">
                <span
                  v-text="formatMoney(row.item.totalReturnAmount)"
                  class="text-right text-danger text-report"
                ></span>
              </div>
            </template>
            <template v-slot:cell(originalPrice)="row">
              <div style="text-align: right">
                <span
                  v-text="formatMoney(row.item.originalPrice)"
                  class="text-right text-report"
                ></span>
              </div>
            </template>
            <template v-slot:cell(totalDiscountAmount)="row">
              <div style="text-align: right">
                <span
                  v-text="formatMoney(row.item.totalDiscountAmount)"
                  class="text-right text-report"
                ></span>
              </div>
            </template>
            <template v-slot:cell(totalRevenue)="row">
              <div style="text-align: right">
                <span
                  v-text="formatMoney(row.item.totalRevenue)"
                  class="text-right text-report"
                ></span>
              </div>
            </template>
            <template v-slot:cell(totalGrossProfit)="row">
              <div style="text-align: right">
                <span
                  v-text="formatMoney(row.item.totalGrossProfit)"
                  class="text-right text-report"
                ></span>
              </div>
            </template>
            <!-- SUM AMOUNT ROW -->
            <template slot="bottom-row">
              <td
                v-bind:colspan="1"
                class="text-right font-weight-bolder"
                style="font-weight: 600,color: '#181c32'"
              >
                <span>Tổng</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span>{{ formatMoney(sumStock) }}</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span>{{ formatMoney(sumSaleShipping) }}</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span>{{ formatMoney(sumReturnShipping) }}</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span>{{ formatMoney(sumSaleRetail) }}</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span>{{ formatMoney(sumReturnRetail) }}</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span>{{ formatMoney(sumSaleWhosesale) }}</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span>{{ formatMoney(sumReturnWhosesale) }}</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span>{{ formatMoney(sumTotalSale) }}</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span>{{ formatMoney(sumTotalOriginalPrice) }}</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span>{{ formatMoney(sumSellingPrice) }}</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span>{{ formatMoney(sumDiscountAmount) }}</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span>{{ formatMoney(sumRevenue) }}</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span>{{ formatMoney(sumGrossProfit) }}</span>
              </td>
            </template>
          </b-table>
        </div>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số :
              {{ totalItem }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="custom-pagination"
              v-if="numberOfPage >= 2"
              :link-gen="linkGen"
              :number-of-pages="numberOfPage"
              use-router
              @change="fetchData"
              align="right"
              first-class="page-item-first btn btn-icon mr-1 btn-sm my-1"
              prev-class="page-item-prev btn btn-icon mr-1 btn-sm my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 "
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-1 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scope>
.d-flex {
  display: -webkit-box !important;
}
.text-title {
  font-weight: 600;
  font-size: 16px;
}
.text-lable-total {
  font-weight: 600;
}
.text-report {
  font-weight: 500;
  font-size: 13px;
}
</style>
<style>
.labelInput {
  font-weight: 600;
}
.productCode:hover {
  text-decoration: underline;
}

.inputText {
  width: 22%;
}
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}

.icon:hover {
  background-color: #90c6fc;
}

.selectedProClass {
  width: 4%;
  text-align: center;
}
.productNameClass {
  width: 20%;
}
.productNameNotPriceClass {
  width: 20%;
}
.providerClass {
  width: 12%;
}
.storeClass {
  width: 12%;
  align-items: center;
}
.importPriceProClass {
  width: 10%;
}
.salePriceProClass {
  width: 10%;
}
.statusProClass {
  width: 15%;
}
.dropdown-header {
  color: #3699ff !important;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
/* a {
  width: 100%;
} */
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

#autosuggest {
  padding-left: 0px;
  padding-right: 25px;
}
.imeiCode:hover {
  text-decoration: underline;
}
.table-wrapper {
  overflow-x: scroll;
  overflow-y: visible;
}
.test {
  position: absolute;
  right: 1vw;
}
.checkbox-label {
  display: block;
}
.multiselect {
  min-height: initial;
}
.multiselect__tags {
  display: block;
  padding: 3px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 12px;
  min-height: initial;
}
.multiselect__element {
  min-height: initial;
  display: block;
  background: #fff;
  font-size: 12px;
}
.multiselect__input {
  min-height: initial;
  display: block;
  background: #fff;
  font-size: 12px;
}
.multiselect__single {
  font-size: 12px;
}
.table-wrap {
  width: 100%;
  overflow-x: auto;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import localData from '../../../utils/saveDataToLocal';
import { VclTable } from 'vue-content-loading';
import { currencyMask, removeAccents } from './../../../utils/common';
// import axios from 'axios';
import decounce from 'debounce';
import axios from 'axios';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import { vi } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';
import { BASE_URL, TIME_TRIGGER } from './../../../utils/constants';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';

export default {
  data() {
    return {
      selectedProduct: '',
      originalPrice: '',
      sellingPrice: '',
      productName: '',
      productId: '',
      hoverPrice: false,
      parentProductId: '',
      childProductId: '',
      selectedBrand: null,
      listBrand: [],
      currentSort: 'createdAt',
      currentSortDir: 'DESC',
      btnCreate: {
        fontWeight: '600!important',
      },
      selected: [],
      checkAllPro: false,
      fields: [
        {
          key: 'productName',
          label: '',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '15%',
          },
        },
        {
          key: 'totalQuantityInStock',
          label: '',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '5%',
          },
          tdClass: 'text-right text-report',
        },
        {
          key: 'saleShipping',
          label: 'Bán',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '6%',
          },
          tdClass: 'text-right text-report',
        },
        {
          key: 'returnShipping',
          label: 'Trả',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '6%',
          },
          tdClass: 'text-right text-report',
        },
        {
          key: 'saleRetail',
          label: 'Bán',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '6%',
          },
          tdClass: 'text-right text-report',
        },
        {
          key: 'returnRetail',
          label: 'Trả',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '6%',
          },
          tdClass: 'text-right text-report',
        },
        {
          key: 'saleWhosesale',
          label: 'Bán',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '6%',
          },
          tdClass: 'text-right text-report',
        },
        {
          key: 'returnWhosesale',
          label: 'Trả',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '6%',
          },
          tdClass: 'text-right text-report',
        },
        {
          key: 'totalSale',
          label: '',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '6%',
          },
          tdClass: 'text-right text-report',
        },
        {
          key: 'originalPrice',
          label: '',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '8%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'sellingPrice',
          label: '',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '8%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'totalDiscountAmount',
          label: '',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '8%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'totalRevenue',
          label: '',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '10%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'totalGrossProfit',
          label: '',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '10%',
          },
          tdClass: 'text-right',
        },
      ],
      search: '',
      listImeis: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      totalItem: 0,
      onLoading: false,
      numberOfPage: 0,
      filteredOptions: [],
      optionsStore: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      listProduct: [],
      showEditPriceModal: false,
      mask: currencyMask,
      listStore: [],
      selectedStore: null,
      searchStock: '',
      listProductType: [
        { id: '', name: 'Tất cả' },
        { id: 1, name: 'Sản phẩm' },
        { id: 2, name: 'Sản phẩm theo IMEI' },
      ],
      selectedProductType: '',
      value: [],
      options: [],
      haveStock: true,
      cateOptions: [
        {
          data: [],
        },
      ],
      filteredCateOptions: [],
      selectedCateId: null,
      selectedCateName: '',
      cate2Options: [
        {
          data: [],
        },
      ],
      filteredCate2Options: [],
      selectedCate2Id: null,
      selectedCate2Name: '',
      brandOptions: [
        {
          data: [],
        },
      ],
      filteredBrandOptions: [],
      selectedBrandId: null,
      selectedBrandName: '',
      dpForm: {
        startDate: moment().subtract(1, 'months').format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY'),
      },
      dpConfigs: {
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      disabledDates: {},
      format: 'dd-MM-yyyy',
      language: vi,
      filteredOptionsEmployee: [],
      optionsEmployee: [
        {
          data: [],
        },
      ],
      selectedEmployee: null,
      searchEmployee: '',
      totalSale: 0,
      totalReturn: 0,
      totalSellingAmount: 0,
      totalReturnAmount: 0,
      totalRevenue: 0,
      fromNo: 0,
      toNo: 0,
      LIMIT_PAGE: 30,
      sumStock: 0,
      sumSaleShipping: 0,
      sumReturnShipping: 0,
      sumSaleRetail: 0,
      sumReturnRetail: 0,
      sumSaleWhosesale: 0,
      sumReturnWhosesale: 0,
      sumTotalSale: 0,
      sumTotalOriginalPrice: 0,
      sumSellingPrice: 0,
      sumDiscountAmount: 0,
      sumRevenue: 0,
      sumGrossProfit: 0,
      listItem: [],
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    datePicker,
    BDropdownCustom
  },
  computed: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Sản phẩm bán chạy', route: '/list-product-sale-reports' },
      { title: 'Sản phẩm bán chạy' },
    ]);
    this.fetchStoreForOption();
    this.fetchStoreByUser();
  },
  created() {
    let imeiCode = this.$route.query.code;
    let searchProduct = this.$route.query.searchProduct;
    let storeId = this.$route.query.storeId;
    if (imeiCode !== undefined) {
      this.search = imeiCode;
    }
    if (searchProduct !== undefined) {
      this.searchProduct = searchProduct;
    }
    if (storeId !== undefined) {
      this.selectedStore = storeId;
    }
    this.fetchCategory();
    this.fetchInternalCategory();
    this.fetchBrand();
    this.checkViewOriginalPrice();
  },
  methods: {
    formatMoney: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    deleteItem: function (item) {
      alert(item);
    },
    fetchData: function () {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      let searchFromDay = this.dpForm.startDate
        ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      let searchToDay = this.dpForm.endDate
        ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      if (!searchFromDay) {
        alert('Bạn chưa chọn ngày bắt đầu!');
        return;
      }
      if (!searchToDay) {
        alert('Bạn chưa chọn ngày kết thúc!');
        return;
      }
      let listId = [];
      if (this.value.length) {
        listId = this.value.map(({ code }) => code);
      };
      if (listId.length === 0) {
        // alert('Vui lòng chọn cửa hàng để xuất báo cáo!');
        // return;
        this.options.forEach((element) => {
          listId.push(element.code);
        });
      }
      if (this.selectedCateName.trim() === '') {
        this.selectedCateId = null;
      }
      if (this.selectedCate2Name.trim() === '') {
        this.selectedCate2Id = null;
      }
      if (this.searchEmployee.trim() === '') {
        this.selectedEmployee = null;
      }
      let param = {
        page: this.page,
        limit: this.LIMIT_PAGE,
        searchProduct: this.searchProduct,
        searchStore: listId,
        productType: this.selectedProductType,
        cateId: this.selectedCateId,
        internalCateId: this.selectedCate2Id,
        fromDate: searchFromDay,
        toDate: searchToDay,
        saleId: this.selectedEmployee,
      };
      let paramQuery = {
        params: param,
      };
      this.onLoading = true;
      this.listItem = [];
      this.sumStock = 0;
      this.sumSaleShipping = 0;
      this.sumReturnShipping = 0;
      this.sumSaleRetail = 0;
      this.sumReturnRetail = 0;
      this.sumSaleWhosesale = 0;
      this.sumReturnWhosesale = 0;
      this.sumTotalOriginalPrice = 0;
      this.sumTotalSale = 0;
      this.sumSellingPrice = 0;
      this.sumDiscountAmount = 0;
      this.sumRevenue = 0;
      this.sumGrossProfit = 0;
      ApiService.setHeader();
      ApiService.query('product-sale-report', paramQuery).then(({ data }) => {
        this.listItem = data.data.data;
        this.numberOfPage = data.data.total_page;
        this.totalItem = data.data.total_row;
        this.onLoading = false;
        this.fromNo = (this.page - 1) * this.LIMIT_PAGE + 1;
        this.toNo = this.LIMIT_PAGE * this.page;
        if (this.toNo > this.totalItem) {
          this.toNo = this.totalItem;
        }
        this.listItem.forEach((element) => {
          this.sumStock += parseInt(element.totalQuantityInStock);
          this.sumSaleShipping += parseInt(element.saleShipping);
          this.sumReturnShipping += parseInt(element.returnShipping);
          this.sumSaleRetail += parseInt(element.saleRetail);
          this.sumReturnRetail += parseInt(element.returnRetail);
          this.sumSaleWhosesale += parseInt(element.saleWhosesale);
          this.sumReturnWhosesale += parseInt(element.returnWhosesale);
          this.sumTotalOriginalPrice += parseInt(element.originalPrice);
          this.sumTotalSale += parseInt(element.totalSale);
          this.sumSellingPrice += parseInt(element.sellingPrice);
          this.sumDiscountAmount += parseInt(element.totalDiscountAmount);
          this.sumRevenue += parseInt(element.totalRevenue);
          this.sumGrossProfit += parseInt(element.totalGrossProfit);
        });
      });
    },
    sortingChanged(ctx) {
      this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      this.currentSort = ctx.sortBy;
      this.fetchData();
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    fetchBrand() {
      this.listBrand = [];
      let param = {
        page: 1,
        pageSize: 1000,
        name: '',
      };
      let paramQuery = {
        params: {
          param,
        },
      };
      ApiService.setHeader();
      ApiService.query('brands', paramQuery).then((response) => {
        response.data.brands.forEach((item) => {
          let brand = {
            id: item.id,
            name: item.name,
          };
          this.brandOptions[0].data.push(brand);
        });
        this.filteredBrandOptions = [{ data: this.brandOptions[0].data }];
      });
    },
    onSelected(option) {
      this.searchStock = option.item.name;
      this.selectedStore = option.item.id;
      //this.fetchData();
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }
      this.searchStore = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsStore[0].data
        .filter((item) => {
          return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);

      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    reportClick: async function () {
      let listId = [];
      if (this.value.length) {
        listId = this.value.map(({ code }) => code);
      }
      if (this.selectedCateName.trim() === '') {
        this.selectedCateId = null;
      }
      if (this.selectedCate2Name.trim() === '') {
        this.selectedCate2Id = null;
      }
      if (this.searchEmployee.trim() === '') {
        this.selectedEmployee = null;
      }
      let searchFromDay = this.dpForm.startDate
        ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      let searchToDay = this.dpForm.endDate
        ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      if (!searchFromDay) {
        alert('Bạn chưa chọn ngày bắt đầu!');
        return;
      }
      if (!searchToDay) {
        alert('Bạn chưa chọn ngày kết thúc!');
        return;
      }
      if (listId.length === 0) {
        // alert('Vui lòng chọn cửa hàng để xuất báo cáo!');
        // return;
        this.options.forEach((element) => {
          listId.push(element.code);
        });
      }
      let searchProduct = this.searchProduct;
      let productType = this.selectedProductType;
      //let baseUrl = 'http://localhost:3000/api/';
      let baseUrl = BASE_URL;
      let url = `${baseUrl}product-sale-report/export-excel?searchProduct=${searchProduct}&cateId=${
        this.selectedCateId
      }&internalCateId=${
        this.selectedCate2Id
      }&productType=${productType}&fromDate=${searchFromDay}&toDate=${searchToDay}&${listId
        .map((n, index) => `searchStore[${index}]=${n}`)
        .join('&')}`;
      await axios({
        url: url,
        method: 'POST',
        responseType: 'blob',
      }).then((response) => {
        let headerLine = response.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('"') + 1;
        let endFileNameIndex = headerLine.lastIndexOf('"');
        let filename = headerLine.substring(
          startFileNameIndex,
          endFileNameIndex
        );
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    debounceInput: decounce(function () {
      this.fetchData();
    }, 2000),
    checkViewOriginalPrice() {
      if (!this.checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')) {
        let listNew = this.fields.filter(
          (item) => item.key !== 'originalPrice'
        );
        this.fields = [];
        listNew.forEach((element) => {
          this.fields.push(element);
        });
        for (let index = 0; index < this.fields.length; index++) {
          const element = this.fields[index];
          if (element.key === 'productName') {
            this.fields[index].tdClass = 'productNameNotPriceClass';
            this.fields[index].thClass = 'productNameNotPriceClass';
            break;
          }
        }
      }
    },
    fetchStoreForOption: async function () {
      ApiService.setHeader();
      ApiService.get(`/stores/getStores`).then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          let option = {
            code: element.id,
            name: element.name,
            shortName: element.shortName,
            checked: false,
          };
          this.options.push(option);
        });
      });
    },

    onSelect(option) {
      // let index = this.options.findIndex((item) => item.name == option.name);
      // this.options[index].checked = true;
      let count = 0;
      this.options.forEach((element) => {
        if (element.checked) {
          count++;
        }
      });
      if (count < 3) {
        let index = this.options.findIndex((item) => item.name == option.name);
        this.options[index].checked = true;
      } else {
        alert('Chỉ được phép chọn tối đa 3 cửa hàng!');
      }
    },

    onRemove(option) {
      let index = this.options.findIndex((item) => item.name == option.name);
      this.options[index].checked = false;
    },
    customLabel(option) {
      return `${option.name}`;
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-product-sale-reports',
      });
      this.fetchData();
    },
    fetchCategory: function () {
      let param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('category', paramQuery).then(({ data }) => {
        this.cateOptions[0].data = [];
        data.data.list_caterogy.forEach((element) => {
          let name = '';
          if (element.level === 1) {
            name = '- ' + element.name;
          } else if (element.level === 2) {
            name = '- - ' + element.name;
          } else if (element.level === 3) {
            name = '- - - ' + element.name;
          } else if (element.level === 4) {
            name = '- - - - ' + element.name;
          } else if (element.level === 5) {
            name = '- - - - - ' + element.name;
          } else if (element.level === 6) {
            name = '- - - - - - ' + element.name;
          } else {
            name = element.name;
          }
          let item = {
            id: element.id,
            suggestionName: name,
            name: element.name,
          };
          this.cateOptions[0].data.push(item);
        });

        this.filteredCateOptions = [{ data: this.cateOptions[0].data }];
      });
    },
    fetchInternalCategory: function () {
      let param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('productExternalCategory/get-all', paramQuery).then(
        ({ data }) => {
          this.cate2Options[0].data = [];
          data.data.forEach((element) => {
            let name = '';
            if (element.level === 1) {
              name = '- ' + element.name;
            } else if (element.level === 2) {
              name = '- - ' + element.name;
            } else if (element.level === 3) {
              name = '- - - ' + element.name;
            } else if (element.level === 4) {
              name = '- - - - ' + element.name;
            } else if (element.level === 5) {
              name = '- - - - - ' + element.name;
            } else if (element.level === 6) {
              name = '- - - - - - ' + element.name;
            } else {
              name = element.name;
            }
            const item = {
              id: element.id,
              suggestionName: name,
              name: element.name,
            };
            this.cate2Options[0].data.push(item);
          });
          this.filteredCate2Options = [{ data: this.cate2Options[0].data }];
        }
      );
    },
    onSelectedCate(option) {
      this.selectedCateId = option.item.id;
      this.selectedCateName = option.item.name;
    },
    onInputCateChange(text) {
      this.selectedCateName = text;

      const filteredData = this.cateOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCateOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedCate2(option) {
      this.selectedCate2Id = option.item.id;
      this.selectedCate2Name = option.item.name;
    },
    onInputCate2Change(text) {
      this.selectedCate2Name = text;

      const filteredData = this.cate2Options[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCate2Options = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedBrand(option) {
      this.selectedBrandId = option.item.id;
      this.selectedBrandName = option.item.name;
    },
    onInputBrandChange(text) {
      this.selectedBrandName = text;

      const filteredData = this.brandOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredBrandOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedEmployee(option) {
      this.selectedEmployee = option.item.id;
      this.searchEmployee = option.item.fullName;
    },
    onInputChangeEmployee(text) {
      if (!text) {
        text = '';
      }
      this.searchEmployee = text;
      this.debounceInputEmployee();
    },
    debounceInputEmployee: decounce(function () {
      this.getListEmployee();
    }, TIME_TRIGGER),
    getListEmployee() {
      this.optionsEmployee[0].data = [];
      this.filteredOptionsEmployee = [{ data: this.optionsEmployee[0].data }];
      // if (this.searchStock.trim() === '') {
      //   this.selectedStore = null;
      // }
      let listStoreId = [];
      this.optionsStore.forEach((element) => {
        if (element.checked) {
          listStoreId.push(element.code);
        }
      });
      ApiService.setHeader();
      ApiService.get(
        `employees/get-by-store?employeeName=${this.searchEmployee}&storeId=${listStoreId}`
      ).then((data) => {
        const employees = data.data.data;
        employees.map((element) => {
          this.optionsEmployee[0].data.push(element);
        });
        this.filteredOptionsEmployee = [{ data: this.optionsEmployee[0].data }];
      });
    },
    fetchStoreByUser() {
      ApiService.setHeader();
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          this.options = [];
          const stores = data.data.stores;
          stores.forEach((element) => {
            let option = {
              code: element.id,
              name: element.name,
              shortName: element.shortName,
              checked: false,
            };
            this.options.push(option);
            if (!data.data.viewAll) {
              let index = this.options.findIndex(
                (item) => item.code == element.id
              );
              this.options[index].checked = true;
              this.value.push(this.options[index]);
            }
          });
        }
        this.fetchData();
      });
    },
  },
};
</script>
